<template>
  <n-dialog-provider>
    <n-message-provider>
      <router-view></router-view>
    </n-message-provider>
  </n-dialog-provider>
</template>

<script>

  export default {
    name: 'App'
  }
</script>

<style>
  html {
    scroll-behavior: smooth;
  }
</style>
