import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import Index from './views/index.vue'
import About from './views/about.vue'
import Edit from './views/edit.vue'

import './assets/css/fonts/linecons/css/linecons.css'
import './assets/css/fonts/fontawesome/css/font-awesome.min.css'
import './assets/css/bootstrap.css'
import './assets/css/xenon-core.css'
import './assets/css/xenon-components.css'
import './assets/css/xenon-skins.css'
import './assets/css/nav.css'

import contextmenu from "v-contextmenu";
import "v-contextmenu/dist/themes/dark.css";

import naive from 'naive-ui'


// 3 创建路由对象
// const router = createRouter({
//   // 3.1 通过 history 属性，指定路由的工作模式
//   history: createWebHashHistory(),
//   // 3.2 通过 routes 数组，指定路由规则
//   // path 是 hash 地址，component 是要展示的组件
//   routes: [
//     { path: '/', component: Index },
//     { path: '/about', component: About },
//     { path: '/edit', component: Edit }
//   ],
// })

const routes = [
  { path: '/', component: Index },
  { path: '/about', component: About },
  { path: '/edit', component: Edit }
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

createApp(App).use(router).use(contextmenu).use(naive).mount('#app')


// const  app = createApp(App)
//
// // 挂载路由模块
// app.use(router)
//
// app.mount('#app')
