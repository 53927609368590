<template>
  <div class="page-container" >
    <div class="sidebar-menu toggle-others fixed" v-contextmenu:contextmenu>
      <div class="sidebar-menu-inner">
        <header class="logo-env">
          <!-- logo -->
          <div class="logo">
            <a href="javascript:void(0)" class="logo-expanded">
              <img src="../assets/images/logo_head.png" width="100%" alt="" />
            </a>
            <a href="javascript:void(0)" class="logo-collapsed">
              <img
                      src="../assets/images/logo-collapsed@2x.png"
                      width="40"
                      alt=""
              />
            </a>
          </div>
          <div class="mobile-menu-toggle visible-xs">
            <a href="javascript:void(0)" data-toggle="user-info-menu">
              <i class="linecons-cog"></i>
            </a>
            <a href="javascript:void(0)" data-toggle="mobile-menu">
              <i class="fa-bars"></i>
            </a>
          </div>
        </header>
        <!-- 侧边栏 -->
        <ul id="main-menu" class="main-menu" >
          <draggable :list="items" animation="500" @end="onDragEnd" :group="menuGroup" >
            <template #item="{ element }">
              <div class="items" @mouseenter="mMenuEnter(element)" @mouseleave="mMenuLeave">
                <a :href="'#' + element.name" class="smooth" >
                  <span class="title">{{ element.name }}</span>
                </a>
              </div>
            </template>
          </draggable>
        </ul>
      </div>
    </div>

    <div class="main-content">
      <!--  顶部 -------------------------------------------------------  -->

      <nav class="navbar user-info-navbar" role="navigation" >
        <ul class="user-info-menu left-links list-inline list-unstyled" >
          <li class="hidden-sm hidden-xs" >
            <a href="javascript:void(0)" data-toggle="sidebar"><i class="fa-bars"></i></a>
          </li>
          <!--  ---------------------------- -->
          <li class="dropdown hover-line language-switcher" >
            <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown" v-contextmenu:contextExportData>
              <img :src="current.flag" /> {{ current.name }}
            </a>
            <ul class="dropdown-menu languages" >
              <li
                      :class="{ active: moduleItem.key === current.key }"
                      v-for="moduleItem in moduleList"
                      :key="moduleItem.key"
              >
                <a href="javascript:void(0)" @click="changModel(moduleItem)">
                  <img :src="moduleItem.flag" /> {{ moduleItem.name }}
                </a>
              </li>
            </ul>
          </li>
          <!-- ------------------------------>
        </ul>
      </nav>

      <!-- 主内容  -------------------------------------------------------  -->

      <div v-for="(item, idx) in items" :key="idx">
        <div v-if="item.web">
          <WebItem :item="item" :onDragEnd="onDragEnd"/>
        </div>
        <div v-else v-for="(subItem, idx) in item.children" :key="idx">
          <WebItem :item="subItem" :onDragEnd="onDragEnd"/>
        </div>
      </div>

      <!--   -------------------------------------------------------  -->

      <Footer />
    </div>
  </div>

  <!-- 右键菜单  -------------------------------------------------------  -->
    <v-contextmenu ref="contextmenu" @show="showVcontext"  >
      <v-contextmenu-item v-if="this.editMenu.show" @click="showCrtItem">创建新链接</v-contextmenu-item>
      <v-contextmenu-item v-if="this.editMenu.show" @click="showEditMenuModal = true, this.crtMenu.name = this.editMenu.selectNode.name">修改菜单名称</v-contextmenu-item>
      <v-contextmenu-item @click="showCrtMenuModal = true, this.crtMenu.name = ''">创建新菜单</v-contextmenu-item>
      <v-contextmenu-item v-if="this.editMenu.show" @click="showDelDialog">删除菜单</v-contextmenu-item>
      <!--      <v-contextmenu-submenu title="特效开关">-->
<!--        <v-contextmenu-item :hide-on-click="false">拖拽排版</v-contextmenu-item>-->
<!--        <v-contextmenu-item :hide-on-click="false">表情特效</v-contextmenu-item>-->
<!--      </v-contextmenu-submenu>-->
    </v-contextmenu>

  <v-contextmenu ref="contextExportData" >
    <v-contextmenu-item @click="showChangeCurrent">修改目录名称</v-contextmenu-item>
    <v-contextmenu-item @click="doExport">导出当前目录</v-contextmenu-item>
    <v-contextmenu-item v-if="this.moduleList.length <3" @click="showInport">导入新目录</v-contextmenu-item>
    <v-contextmenu-item v-if="this.moduleList.length <3" @click="showCrtEmptyModal">创建空白目录</v-contextmenu-item>
    <v-contextmenu-item v-if="this.current.key!='tt:template:default'" @click="delModal">删除目录</v-contextmenu-item>
    <v-contextmenu-item v-if="this.current.key=='tt:template:default'" @click="resertDefault">恢复出厂设置</v-contextmenu-item>
  </v-contextmenu>
  <!-- 右键菜单弹框  -------------------------------------------------------  -->

  <n-modal v-model:show="showCrtMenuModal" preset="dialog" title="新建菜单" positive-text="确认"
           negative-text="取消" @positive-click="doCrtMenu">
    <n-space vertical>
      <n-input v-model:value="crtMenu.name" placeholder="新建菜单名称" />
    </n-space>
  </n-modal>

  <n-modal v-model:show="showEditMenuModal" preset="dialog" title="修改菜单" positive-text="确认"
           negative-text="取消" @positive-click="doEditMenu">
    <n-space vertical>
      <n-input v-model:value="crtMenu.name" placeholder="菜单名称" />
    </n-space>
  </n-modal>

  <n-modal v-model:show="showCrtModule" preset="dialog" title="导入模板" positive-text="确认"
           negative-text="取消" @positive-click="doImport">
    <n-space vertical>
      <n-input v-model:value="crtModuleName" placeholder="名称" />
    </n-space>
    <n-upload
            multiple
            directory-dnd
            :max="1"
            v-model:file-list="fileList"
    >
      <n-upload-dragger>
        <div style="margin-bottom: 12px">
          <n-icon size="48" :depth="3">
            <archive-icon />
          </n-icon>
        </div>
        <n-text style="font-size: 16px">
          点击或者拖动文件到该区域来上传
        </n-text>
        <n-p depth="3" style="margin: 8px 0 0 0">
          文件数据格式请参考导出目录文件
        </n-p>
      </n-upload-dragger>
    </n-upload>
  </n-modal>



  <n-modal v-model:show="showEditModal" preset="dialog" title="修改目录" positive-text="确认"
           negative-text="取消" @positive-click="doEditModal">
    <n-space vertical>
      <n-input v-model:value="editModalName" placeholder="目录名称" />
    </n-space>
  </n-modal>

  <n-modal v-model:show="crtCrtNewModule" preset="dialog" title="创建空目录" positive-text="确认"
           negative-text="取消" @positive-click="doCrtEmptyModal">
    <n-space vertical>
      <n-input v-model:value="editModalName" placeholder="目录名称" />
    </n-space>
  </n-modal>



  <n-modal v-model:show="showCrtLinkModal" preset="dialog" title="新建链接" positive-text="确认"
           negative-text="取消" @positive-click="submitLinkCrt">
    <n-space vertical>
      <n-input v-model:value="editMenu.selectNode.name" placeholder="菜单名称" disabled="true"  />
      <n-input v-model:value="linkValue.title" placeholder="名称" />
      <n-input v-model:value="linkValue.url" placeholder="地址" />
      <n-input v-model:value="linkValue.desc" placeholder="描述" />
    </n-space>
  </n-modal>
  <!-- ----------------------------->

</template>

<script>
  import WebItem from "../components/WebItem.vue";
  import Footer from "../components/Footer.vue";
  import itemsData from "../assets/data.json";
  import { loadJs } from '../assets/js/app.js';
  import draggable from 'vuedraggable'
  import dataIncrease from "@/assets/dataIncrease.json";
  import { useMessage, useDialog } from 'naive-ui'
  import { saveAs } from 'file-saver';
  import { ArchiveOutline as ArchiveIcon } from '@vicons/ionicons5'

  export default {
    name: "Index",
    components: {
      WebItem,
      Footer,
      draggable,
      ArchiveIcon
    },
    created() {
      loadJs();
      this.initItemData();
    },
    data() {
      return {
        dialog: useDialog(),
        message: useMessage(),
        showCrtLinkModal:false,
        showCrtMenuModal:false,
        showEditMenuModal:false,
        showCrtModule:false,
        crtCrtNewModule:false,
        showEditModal:false,
        items: itemsData.items,
        version:itemsData.version,
        editModalName:'',
        menuGroup: {
          name: "menuGroup",
          pull: false, //允许拖出
        },
        crtModuleName:'',
        fileList:[],
        editMenu:{
          show:false,
          data:'',
          selectNode:''
        },
        linkValue:{
          url:"",
          title:"",
          desc:""
        },
        initDefaultCurrent:[{
          key:"tt:template:default",
          name: "默认导航",
          // flag: "./assets/images/flags/flag-1.png",
        }],
        crtMenu:{name:''},
        current: {name:'',flag:'',key:''},
        moduleList: []
      };
    },

    methods: {
      changModel(currentItem){
        if(this.current.key != currentItem.key){
          this.current = currentItem;
          var storeModuleList = window.localStorage.getItem("tt:template:list");
          storeModuleList = JSON.parse(storeModuleList);
          storeModuleList.current = currentItem

          var storeDataStr = window.localStorage.getItem(this.current.key);
          var storeData = JSON.parse(storeDataStr);
          this.items = storeData.items
          window.localStorage.setItem("tt:template:list",JSON.stringify(storeModuleList));

          // window.localStorage.setItem(key,JSON.stringify(data));
        }
      },
      initModule(){
        var storeModuleList = window.localStorage.getItem("tt:template:list");
        if(storeModuleList==null){

          this.moduleList = this.initDefaultCurrent;
          this.current = this.initDefaultCurrent[0];

          storeModuleList = {
            "current":this.initDefaultCurrent[0],
            "list":this.initDefaultCurrent
          }
          // this.moduleList = {};
          // this.moduleList.current = defaultModuleList[0];
          // this.moduleList.list = defaultModuleList;
          // this.current = this.moduleList[0];
          window.localStorage.setItem("tt:template:list",JSON.stringify(storeModuleList));
        }else{
          storeModuleList = JSON.parse(storeModuleList);
          for(var i = 0; i < storeModuleList.list.length; i++){
            storeModuleList.list[i].flag = "./assets/images/flags/flag-"+(i+1)+".png";
            if(storeModuleList.current.key == storeModuleList.list[i].key){
              storeModuleList.current.flag = "./assets/images/flags/flag-"+(i+1)+".png";
            }
          }
          this.moduleList = storeModuleList.list;
          this.current = storeModuleList.current;
        }
      },
      initItemData(){
        this.initModule();

        var storeDataStr = window.localStorage.getItem(this.current.key);
        if(storeDataStr == null){
          window.localStorage.setItem(this.current.key,JSON.stringify(itemsData));
        }else{
          var storeData = JSON.parse(storeDataStr);

          this.items = storeData.items
          var storeDataVersion = storeData.version;
          if(this.version > storeDataVersion){

            // alert("有新版本"+incVersion);

            //遍历多个版本
            for(var i = storeDataVersion+1; i <= this.version; i++){
              var incDataList = dataIncrease[i];
              // console.log("获取版本数据"+i,incDataList);
              //遍历新版本增量包的数据
              for(var j = 0 ; j < incDataList.length; j++){
                var incData = incDataList[j];
                var key = incData.key;
                var exist = false;
                //比对storeData里面的
                for(var k =0; k < this.items.length; k++){
                  if(key == this.items[k].key){
                    this.items[k].web.push(...incData.web)
                    this.items[k].name = incData.name
                    exist = true;
                    break;
                  }
                }
                if(!exist){
                  this.items.push(incData)
                }
              }
            }
            //保存
            this.onDragEnd();

          }else{
            // alert("版本一致");
          }
        }
      },
      onDragEnd(){
        var data = {
          version:this.version,
          items:this.items
        }
        window.localStorage.setItem(this.current.key,JSON.stringify(data));
      },
      doCrtMenu(){
        if(this.crtMenu.name==''){
          this.message.error('请输入菜单名称')
          return;
        }
        var newId = Date.now();
        var newMenu = {
          "key": newId,
          "name": this.crtMenu.name,
          "en_name": this.crtMenu.name,
          "icon": "linecons-star",
          "web":[]
        }
        this.items.push(newMenu)
        this.onDragEnd();
      },
      doEditMenu(){
        if(this.crtMenu.name==''){
          this.message.error('修改失败，请输入菜单名称')
        }
        this.editMenu.selectNode.name=this.crtMenu.name
        this.onDragEnd();
      },
      doEditModal(){
        if(this.editModalName==''){
          this.message.error('修改失败，请输入目录名称')
        }
        this.current.name = this.editModalName;
        this.onDragEnd();
      },
      mMenuEnter(element){
        this.editMenu.data = element
      },
      mMenuLeave(){
        this.editMenu.data = ''
      },
      showVcontext(){
        if(this.editMenu.data == ''){
          //如果没选中菜单单机了右键，则隐藏删除
          this.editMenu.show = false;
        }else{
          this.editMenu.show = true;
          this.editMenu.selectNode = this.editMenu.data;
        }
      },
      showDelDialog(){
        // alert(JSON.stringify(this.editMenu.selectNode));
        //没有子菜单直接删除
        if(this.editMenu.selectNode.web.length == 0){
          // alert(JSON.stringify(this.items));
          this.doDelMenu()
        }else{
          this.dialog.warning({
            title: '警告',
            content: '['+this.editMenu.selectNode.name+']菜单下存在导航地址,您确定要删除吗？',
            positiveText: '确定',
            negativeText: '取消',
            onPositiveClick: () => {
              this.doDelMenu()
            },
            onNegativeClick: () => {
              // this.message.error('不确定')
            }
          })
        }
      },
      doDelMenu(){
        for (let i = 0; i< this.items.length; i++){
          if (this.items[i] == this.editMenu.selectNode){
            this.items.splice(i, 1);
            break;
          }
        }
        this.onDragEnd();
        this.message.success("删除成功")
      },
      showCrtItem(){
        this.showCrtLinkModal = true
        this.linkValue = {
          url: '',
          title:'',
          desc:''
        }
      },
      submitLinkCrt(){
        var logoPath = this.functiongetStrOrigin(this.linkValue.url,true)+"/favicon.ico";
        var newWeb = {
          url:this.linkValue.url,
          title:this.linkValue.title,
          desc:this.linkValue.desc,
          logo:logoPath
        }
        this.editMenu.selectNode.web.push(newWeb);
        this.onDragEnd();
      },
      doExport(){
        let str = JSON.stringify(this.items, null, '\t')
        let strData = new Blob([str], { type: 'text/plain;charset=utf-8' });
        saveAs(strData, this.current.name+".txt");
      },
      showInport(){
        this.fileList = [];
        this.crtModuleName='';
        this.showCrtModule = true;
      },
      showCrtEmptyModal(){
        this.crtCrtNewModule = true;
        this.editModalName = '';

      },
      doCrtEmptyModal(){
        if(this.editModalName==''){
          this.message.error('创建失败，请输入目录名称')
        }

        let timestamp = new Date().getTime();
        var key = "tt:template:"+timestamp;
        var newModule = {
          key:key,
          name: this.editModalName,
          // flag: "./assets/images/flags/flag-1.png",
        }
        this.moduleList.push(newModule);
        this.current=newModule;
        var storeModuleList = {
          "current":newModule,
          "list":this.moduleList
        }
        window.localStorage.setItem("tt:template:list",JSON.stringify(storeModuleList));

        var storeData = {
          version:"9999",
          items:[]
        }
        window.localStorage.setItem(key,JSON.stringify(storeData));
        this.items = [];
        this.initModule();
        // window.location.reload()
      },
      resertDefault(){
        this.dialog.warning({
          title: '警告',
          content: '您确定要恢复到系统默认目录吗？',
          positiveText: '确定',
          negativeText: '取消',
          onPositiveClick: () => {
            this.version = itemsData.version,
            this.items = itemsData.items
            window.localStorage.setItem("tt:template:default",JSON.stringify(itemsData));
          },
          onNegativeClick: () => {
            // this.message.error('不确定')
          }
        })
      },
      delModal(){

        this.dialog.warning({
          title: '警告',
          content: '您确定要删除['+this.current.name+']目录吗？',
          positiveText: '确定',
          negativeText: '取消',
          onPositiveClick: () => {

            for(var i = 0; i < this.moduleList.length; i++){
              if(this.current.key == this.moduleList[i].key){
                window.localStorage.removeItem(this.current.key);
                this.moduleList.splice(i, 1);
                this.current = this.initDefaultCurrent[0];

                let storeModuleList = {
                  "current":this.initDefaultCurrent[0],
                  "list":this.moduleList
                }
                var defaultDataStrt = window.localStorage.getItem("tt:template:default");
                var defaultData = JSON.parse(defaultDataStrt);
                this.items = defaultData.items;
                window.localStorage.setItem("tt:template:list",JSON.stringify(storeModuleList));
                break;
              }
            }
            this.initModule();
          },
          onNegativeClick: () => {
            // this.message.error('不确定')
          }
        })

      },
      showChangeCurrent(){
        this.showEditModal = true;
        this.editModalName = this.current.name;
      },
      doImport(){
        if(this.crtModuleName==''){
          this.message.error('创建失败，请输入模板名称')
          return;
        }
        if(this.fileList.length == 0){
          this.message.error('创建失败，请上传导入模板文件')
          return;
        }
        var file = this.fileList[0]
        const reader = new FileReader()
        reader.readAsText(file.file, "UTF-8")
        reader.onload = (evt) => {
          try{
            var fileString = evt.target.result
            var data = JSON.parse(fileString);

            //校验格式
            if(data.length == 0){
              this.message.error('创建失败，未检测到文件数据')
              return;
            }
            for(var i = 0; i < data.length; i++){
              if(data[i].name==""){
                this.message.error("第文件"+(i+1)+"个菜单格式错误，名称不能为空");
                return;
              }
              if(data[i].web==null|| data[i].web==undefined){
                this.message.error("第文件"+(i+1)+"个菜单格式错误，链接不能为空");
                return;
              }
            }
            let timestamp = new Date().getTime();
            var key = "tt:template:"+timestamp;
            var newModule = {
              key:key,
              name: this.crtModuleName,
              // flag: "./assets/images/flags/flag-us.png",
            }
            this.moduleList.push(newModule);
            this.current=newModule;
            var storeModuleList = {
              "current":newModule,
              "list":this.moduleList
            }
            window.localStorage.setItem("tt:template:list",JSON.stringify(storeModuleList));

            this.items = data;

            var storeData = {
              version:"9999",
              items:data
            }

            window.localStorage.setItem(key,JSON.stringify(storeData));
            this.initModule();
          }catch (e) {
            this.message.error('文件解析异常，请检查文件内容是否正确')
            return;
          }
        }
      },
      functiongetStrOrigin(val, isFullPath) {
        let reg1 = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;//获取字符串中的地址正则
        let reg2 = /((https|http|ftp|file):\/\/)([A-Za-z0-9\-.]+)(:[0-9]+){0,1}/g;//获取字符串中的域名或IP地址
        let reg = isFullPath ? reg1 : reg2;
        let text = '';
        if (val && typeof val === 'string') {
          const reg = /((https|http|ftp|file):\/\/)([A-Za-z0-9\-.]+)(:[0-9]+){0,1}/g;
          const arr = val.match(reg);
          if (arr && arr.length > 0) {
            text = arr[0];
          } else {
            //
          }
        } else {
          //
        }
        return text;
      }
    },

  };
</script>

<style>
</style>
