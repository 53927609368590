<template>
  <div>
    <h4 class="text-gray">
      <span  v-contextmenu:newItemWeb="{ trigger: ['click', 'contextmenu'] }" >
        <i class="linecons-tag" :id="item.name"></i>{{item.name}}
      </span>

    </h4>
    <div class="row">
      <!-- -->
      <draggable :list="item.web" animation="500"  :group="webItemGroup" @end="onDragEnd">
        <template #item="{ element }">
          <div class="col-sm-3" v-contextmenu:delItemRef @mouseenter="mEnter(element)">
              <div class="xe-widget xe-conversations box2 label-info" title=""
                @click="openweb(element.url)"
                data-toggle="tooltip"
                data-placement="bottom"
                :data-original-title="element.url">

                <div class="xe-comment-entry">
                  <a class="xe-user-img">
                    <img :src="element.logo" class="lozad img-circle" width="40">
                  </a>
                  <div class="xe-comment">
                    <a href="#" class="xe-user-name overflowClip_1">
                      <strong>{{element.title}}</strong>
                    </a>
                    <p class="overflowClip_2">{{element.desc}}</p>
                  </div>
                </div>
              </div>
            </div>
        </template>
      </draggable>
      <!-- -->
    </div>

    <br />
  </div>

  <!--   -------------------------------------------------------  -->
  <v-contextmenu ref="delItemRef" >
    <v-contextmenu-item @click="showEditItem()">编辑</v-contextmenu-item>
    <v-contextmenu-item @click="delItem">删除</v-contextmenu-item>
  </v-contextmenu>

  <v-contextmenu ref="newItemWeb" >
    <v-contextmenu-item @click="showCrtItem()">创建新链接</v-contextmenu-item>
  </v-contextmenu>
  <!--   -------------------------------------------------------  -->
  <n-modal v-model:show="showEditModal" preset="dialog" title="编辑" positive-text="确认"
           negative-text="取消" @positive-click="submitEdit">
    <n-space vertical>
      <n-input v-model:value="formValue.title" placeholder="名称" />
      <n-input v-model:value="formValue.url" placeholder="地址" />
      <n-input v-model:value="formValue.desc" placeholder="描述" />
    </n-space>
  </n-modal>

  <n-modal v-model:show="showCrtModal" preset="dialog" title="新建链接" positive-text="确认"
           negative-text="取消" @positive-click="submitCrt">
    <n-space vertical>
      <n-input v-model:value="item.name" placeholder="菜单名称" disabled="true"  />
      <n-input v-model:value="formValue.title" placeholder="名称" />
      <n-input v-model:value="formValue.url" placeholder="地址" />
      <n-input v-model:value="formValue.desc" placeholder="描述" />
    </n-space>
  </n-modal>

</template>

<script>
import draggable from 'vuedraggable'
import { useMessage, useDialog } from 'naive-ui'

export default {
  name: 'WebItem',
  components: {
    draggable,
  },
  props: {
    item: Object,
    onDragEnd:Function
  },
  data() {
    return {
      dialog: useDialog(),
      message: useMessage(),
      showEditModal:false,
      showCrtModal:false,
      formValue:{
        url:"",
        title:"",
        desc:""
      },
      webItemGroup: {
        name: "webItemGroup",
        put: true, //允许拖入
        pull: () => {
          let res = this.item.web.length > 1;
          return res;
        },
      },
      cureNode:""
    }
  },
  methods: {
    openweb(url) {
      window.open(url, '_blank');
    },
    delItem(){
      this.dialog.warning({
        title: '警告',
        content: '你确定要删除'+this.cureNode.title+'？',
        positiveText: '确定',
        negativeText: '取消',
        onPositiveClick: () => {
          for (let i = 0; i< this.item.web.length; i++){
            if (this.item.web[i] == this.cureNode){
              this.item.web.splice(i, 1);
              break;
            }
          }
          this.onDragEnd();
          this.message.success("删除成功")
        },
        onNegativeClick: () => {
        }
      })
    },
    showEditItem(){
      this.showEditModal = true
      this.formValue = {
        url: this.cureNode.url,
        title:this.cureNode.title,
        desc:this.cureNode.desc
      }
    },
    submitEdit(){
      for (let i = 0; i< this.item.web.length; i++){
        if (this.item.web[i] == this.cureNode){
          this.item.web[i].url = this.formValue.url;
          this.item.web[i].title = this.formValue.title;
          this.item.web[i].desc = this.formValue.desc;
          this.item.web[i].logo = this.functiongetStrOrigin(this.formValue.url,true)+"/favicon.ico";
          break;
        }
      }
      this.onDragEnd();
    },
    showCrtItem(){
      this.showCrtModal = true
      this.formValue = {
        url: '',
        title:'',
        desc:''
      }
    },
    submitCrt(){
      var logoPath = this.functiongetStrOrigin(this.formValue.url,true)+"/favicon.ico";
      var newWeb = {
        url:this.formValue.url,
        title:this.formValue.title,
        desc:this.formValue.desc,
        logo:logoPath
      }
      this.item.web.push(newWeb);
      this.onDragEnd();
    },
    mEnter(element){
      this.cureNode = element
      // console.log(element);
    },
    functiongetStrOrigin(val, isFullPath) {
      let reg1 = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;//获取字符串中的地址正则
      let reg2 = /((https|http|ftp|file):\/\/)([A-Za-z0-9\-.]+)(:[0-9]+){0,1}/g;//获取字符串中的域名或IP地址
      let reg = isFullPath ? reg1 : reg2;
      let text = '';
      if (val && typeof val === 'string') {
        const reg = /((https|http|ftp|file):\/\/)([A-Za-z0-9\-.]+)(:[0-9]+){0,1}/g;
        const arr = val.match(reg);
        if (arr && arr.length > 0) {
          text = arr[0];
        } else {
          //
        }
      } else {
        //
      }
      return text;
    }
  }
}
</script>

<style scoped>
i {
  margin-right: 7px;
}
</style>
